import React from "react"
import lm_hs from "../../assets/images/lm_hs.png"

export default class LMHS extends React.Component {

  render() {
    return (
      <div className="page" id="lm-hs-page">
        <div className="center white-back">
          <div className="page-banner">Liquid Metal Heatsink (LM-HS)</div>
          <div className="split-section">
            <div className="left-half">
              <img src={lm_hs} />
            </div>
            <div className="right-half">
              <div className="page-section">
                <div className="page-section-content">
                  The LM-HS is a heat sink with a reservoir structure, which
                  consists of a tube type of reservoir, a seal ring and a
                  connecting hole. This design allows for the safe use of liquid
                  metal TIM1.5 and TIM2, and can be assembled with an LM-Lid to
                  create a whole liquid metal thermal connection system. 
                </div>
                <div className="page-section-content font-gray">
                  STATUS: IN DEVELOPMENT
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}