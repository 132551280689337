import React from "react"

import lm_vc_2 from "../../assets/images/lm_vc_2.jpg"

export default class LMVCLid extends React.Component {
  render() {
    return (
      <div className="page" id="lm-vc-page">
        <div className="center white-back">
          <div className="page-banner">
            Liquid Metal Vapor Chamber Lid (LM-VC-Lid)
          </div>
          <div className="split-section">
            <div className="left-half">
              <img src={lm_vc_2} />
            </div>
            <div className="right-half">
              <div className="page-section">
                <div className="page-section-content">
                  LM-VC-Lid is a VC-IHS with a reservoir structure, which consists
                  of a tunnel type of reservoir, a seal ring and a connecting
                  hole. This design has even better thermal performance than the
                  LM-Lid, and can further reduce IC chip operating temperatures.
                </div>
                <div className="page-section-content font-gray">
                  STATUS: IN DEVELOPMENT
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}