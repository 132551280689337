import React from "react"

export default class About extends React.Component {

  render() {
    return (
      <div className="page" id="about-page">
        <div className="center white-back">
          <div className="page-banner">Company Bio</div>
          <div className='two-third'>
            <div className="page-section">
              <div className="page-section">
                <div className="page-section-title">Our Background</div>
                <div className="page-section-content">
                  We are an R&D team located in San Jose, California who have
                  patented and prototyped our first product, the LM-Lid, which
                  perfectly resolves the TIM1 issue when cooling IC chips in lidded
                  flip chip packages by enabling the industrial use of liquid metal
                  thermal interface materials (LM-TIM). Our goal is to eventually
                  apply our technology across the entire IC chip cooling system
                  to create a whole liquid metal thermal connection between the
                  IC chip and its cooling system. 
                </div>
              </div>
              <div className="page-section-title">The Problem</div>
              <div className="page-section-content">
                Efficiently cooling IC chips in lidded flip chip packages is
                crucial for the IC chip industry. As the power of IC chips is
                getting higher and higher, their temperature rise caused by the
                thermal interface material (TIM1) in lidded flip chip packages has
                become a bottleneck in cooling IC chip. There are two requirements for a good TIM1:
                <ol>
                  <li>Low thermal impedance for quickly transferring heat</li>
                  <li>High flexibility for avoiding additional stress</li>
                </ol>
                Until now, no product has been able to perfectly meet both requirements. 
              </div>
            </div>
            <div className="page-section">
              <div className="page-section-title">Our Solution</div>
              <div className="page-section-content">
                The LM-Lid is one of our patented products that perfectly meets
                both requirements, making it a unique solution to the TIM1
                problem. The LM-Lid consists of a reservoir structure built into
                the lid to allow for the safe use of LM-TIM in lidded flip chip
                packages, in which a very thin LM-TIM is provided between the lid
                and flip chip without causing additional stress. This completely
                eliminates the thermal bottleneck caused by TIM1. 
              </div>
            </div>
            <div className="page-section">
              <div className="page-section-title">Target Market</div>
              <div className="page-section-content">
                Our initial target market is for the high-end application of IC
                chip processing units, such as module packages of CPU/GPU
                integrated with HBM. These packages are used extensively in
                datacenters, AI applications, and self-driving cars, all of which
                have extremely high processing power and power consumption requirements.
                So, they urgently need an effective thermal solution for TIM1.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}