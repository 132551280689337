import React from "react";

import PDF from "../../assets/pdfs/ICCT LM-Lid Production Method.pdf";

class Patents extends React.Component {
  constructor() {
    super();
    this.state = {
      modal: true
    }
  }

  renderModal() {
    if (this.state.modal) {
      return (
        <div className="modal"></div>
      )
    }
  }

  render() {
    return (
      <div className="page" id="patent-page">
        <div className="center white-back">
          <div className="page-banner">Patents and Publications</div>
          <div className="patent-info">
            <a href={PDF} download className="patent-title">
              Heat-Dissipating Lid with Reservoir Structure and Associated
              Lidded Flip Chip Package Allowing for Liquid Thermal Interfacing
              Materials
            </a>
            <div className="patent-info-block">
              <strong>Patent Number: </strong>10643924
            </div>
            <div className="patent-info-block">
              <strong>Abstract: </strong>The disclosure describes a
              heat-dissipating object having a reservoir structure so that a
              reservoir system can be formed in an electronic device, allowing
              for a liquid TIM (thermal interface material) in the gap between
              the heat-dissipating object and the heat-generating object of the
              electronic device. The reservoir structure comprises a seal ring,
              a connecting hole and a reservoir which is a space for taking in a
              liquid material and releasing it again when needed. As a specific
              case of the heat-dissipating object and the electronic device, a
              lid having a reservoir structure and a lidded flip chip package
              based on the lid are particularly described in details of the
              embodiments of the present invention.
            </div>
          </div>
          <div className="patent-info">
            <a href={PDF} download className="patent-title">
              Package Aspect of Heat-Dissipating Lid and Reservoir Structure for
              Liquid Thermal Interfacing Materials
            </a>
            <div className="patent-info-block">
              <strong>Publication Number:</strong> 20200183323
            </div>
            <div className="patent-info-block">
              <strong>Abstract: </strong>The disclosure describes a
              heat-dissipating object having a reservoir structure so that a
              reservoir system can be formed in an electronic device, allowing
              for a liquid TIM in the gap between the heat-dissipating object
              and the heat-generating object of the electronic device. The
              reservoir structure comprises a seal ring, a connecting hole and a
              reservoir which is a space for taking in a liquid material and
              releasing it again when needed. As a specific case of the
              heat-dissipating object and the electronic device, a lid having a
              reservoir structure and a lidded flip chip package based on the
              lid are particularly described in details of the embodiments of
              the present invention.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Patents
