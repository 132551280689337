import React from "react"

// First layer, has the logo and a "contact us" button
export default class NavLogo extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="nav-logo">
        <div className="center">
          <div className="logo" onClick={() => this.props.changePage(0)}>
            <div className="abbreviated-logo">ICCT</div>
            <div className="full-logo">IC CHIP COOLING TECHNOLOGIES LLC</div>
          </div>
          <div id="contact-button" onClick={() => this.props.toggleModal(1)}>
            Contact Us
          </div>
        </div>
      </div>
    );
  }
}