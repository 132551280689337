import React from "react"

import lm_vc_1 from "../../assets/images/lm_vc_1.png"

export default class LMVC extends React.Component {
  render() {
    return (
      <div className="page" id="lm-vc-page">
        <div className="center white-back">
          <div className="page-banner">Liquid Metal Vapor Chamber (LM-VC)</div>
          <div className="split-section">
            <div className="left-half">
              {/* <img src={lm_vc_2} /> */}
              <img src={lm_vc_1} />
            </div>
            <div className="right-half">
              <div className="page-section">
                <div className="page-section-content">
                  The LM-VC is a vapor chamber with a reservoir structure, which
                  consists of a tube type of reservoir, a seal ring and a
                  connecting hole. This design can be assembled with a
                  traditional heatsink to allow for the safe use of TIM1.5 and
                  TIM2 to create a powerful cooling device. The demand for the LM-VC is expected to become
                  extremely high as high-end IC chips become increasingly
                  powerful.
                </div>
                <div className="page-section-content font-gray">
                  STATUS: IN DEVELOPMENT
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}