import React from "react";
import Carousel from 'react-elastic-carousel'

import system_diag from "../../assets/images/system_diagram.jpg";

import system_thermal from "../../assets/images/system_thermal.jpg"
import assembly from "../../assets/images/Assembly Both Ways.gif"
import mechanism from "../../assets/images/mechanism_long.gif"

import assembly_webp from "../../assets/images/Assembly Both Ways.webp"
import mechanism_webp from "../../assets/images/mechanism_long.webp"

import {isWebpSupported} from 'react-image-webp/dist/utils';

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded_mech: false,
      loaded_ass: false
    }
  }

  handleLoad(type) {
    switch(type) {
      case 1: this.setState({loaded_mech: true}); return;
      case 2: this.setState({loaded_ass: true}); return;
    }
  }

  renderLoading(type) {
    let {loaded_mech, loaded_ass} = this.state;
    switch(type) {
      case 1: 
        return !loaded_mech ? <div className="loading-home"><div>Loading...</div><div>(can also right click on gray area to download)</div></div> : <div></div>;
      case 2: 
        return !loaded_ass ? <div className="loading-home"><div>Loading...</div><div>(can also right click on gray area to download)</div></div> : <div></div>;
    }
  }

  render() {
    return (
      <div className="page" id="home-page">
        {/* <img src={background} className="background-image" /> */}
        <div className="center">
          <div id="home-top">
            Developers of a unique technique that allows for the industrial
            application of liquid metal thermal interface materials in IC chip
            systems
          </div>
          <div id="home-bottom">
            <div id="home-image">
              <img src={system_diag} />
              <div id="home-image-desc">
                Whole Liquid Metal Connection for Superior IC Chip Cooling
              </div>
            </div>
            <div id="home-carousel-wrapper">
              <Carousel itemsToShow={1} id="home-carousel">
                <div className="home-slide">
                  <div className="slide-title">Thermal Performance</div>
                  <div className="slide-content">
                    <img src={system_thermal} id="system_thermal"/>
                    
                  </div>
                </div>
                <div className="home-slide">
                <div className="slide-title blue-link" onClick={() => this.props.changePage(7, 1)}>LM-Lid Working Principle (click here)</div>
                  <div className="slide-content">
                    <img src={isWebpSupported() ? mechanism_webp : mechanism} id="mechanism-gif" onLoad={() => this.handleLoad(1)}/>
                    {this.renderLoading(1)}
                  </div>
                </div>

                <div className="home-slide">
                  <div className="slide-title blue-link" onClick={() => this.props.toggleModal(2)}>Whole Liquid Metal System Assembly Process (click here)</div>
                  <div className="slide-content">
                    <img src={isWebpSupported() ? assembly_webp : assembly} id="assembly-gif" onLoad={() => this.handleLoad(2)}/>
                    {this.renderLoading(2)}
                  </div>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
