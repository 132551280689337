import React from "react";
import tds from "../../assets/pdfs/ICCT TDS.pdf";
import production from "../../assets/pdfs/ICCT LM-Lid Production Method.pdf";

// First layer, has the logo and a "contact us" button
export default class NavButtons extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // 0 is none, 1 is abt, 2 is fund, 3 is abt
      list: 0,
    };
  }

  hideModal() {
    this.setState({ list: 0 });
  }

  showModal(number) {
    this.setState({ list: number });
  }

  render() {
    return (
      <div id="nav-buttons">
        <div className="center">
          <div className="nav-button" onClick={() => this.props.changePage(0)}>
            HOME
          </div>
          <div
            className="nav-button"
            onMouseEnter={() => this.showModal(1)}
            onMouseLeave={() => this.hideModal()}
            style={{display: "none"}}
          >
            ABOUT US &#9660;
            <div className={`nav-dropdown ${this.state.list == 1 ? "" : "hidden"}`}>
              <div className="drop-button" onClick={() => this.props.changePage(1)}>COMPANY BIO</div>
              {/* <div className="drop-button" onClick={() => this.props.changePage(2)}>TEAM</div> */}
              <div className="drop-button" onClick={() => this.props.changePage(3)}>PATENTS / PUBLICATIONS</div>
            </div>
          </div>
          <div className="nav-button" onClick={() => this.props.changePage(1)}>ABOUT US</div>
          <div
            className="nav-button"
            onMouseEnter={() => this.showModal(2)}
            onMouseLeave={() => this.hideModal()}
          >
            FUNDAMENTALS &#9660;
            <div className={`nav-dropdown ${this.state.list == 2 ? "" : "hidden"}`}>
              <div className="drop-button" onClick={() => this.props.changePage(4)}>LIQUID METAL</div>
              <div className="drop-button" onClick={() => this.props.changePage(5)}>WARPAGE</div>
              <div className="drop-button" onClick={() => this.props.changePage(6)}>THERMAL INTERFACE MATERIALS</div>
            </div>
          </div>
          <div
            className="nav-button"
            onMouseEnter={() => this.showModal(3)}
            onMouseLeave={() => this.hideModal()}
          >
            PRODUCTS &#9660;
            <div className={`nav-dropdown ${this.state.list == 3 ? "" : "hidden"}`}>
              <div className="drop-button" onClick={() => this.props.changePage(7)}>LIQUID METAL LID (LM-LID)</div>
              <div className="drop-button" onClick={() => this.props.changePage(10)}>LIQUID METAL VAPOR CHAMBER LID (LM-VC-LID)</div>
              <div className="drop-button" onClick={() => this.props.changePage(8)}>LIQUID METAL HEATSINK (LM-HS)</div>
              <div className="drop-button" onClick={() => this.props.changePage(9)}>LIQUID METAL VAPOR CHAMBER (LM-VC)</div>
            </div>
          </div>
          <div
            className="nav-button"
            onMouseEnter={() => this.showModal(4)}
            onMouseLeave={() => this.hideModal()}
          >
            DATASHEETS &#9660;
            <div className={`nav-dropdown ${this.state.list == 4 ? "" : "hidden"}`}>
                <a href={tds} download className="drop-button">
                  <div className="fa fa-download drop-symbol"></div>
                  &nbsp;TDS
                </a>
                <a href={production} download className="drop-button">
                  <div className="fa fa-download drop-symbol"></div>
                  &nbsp;PRODUCTION METHODS
                </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
