import React from "react"
import Carousel from 'react-elastic-carousel'

import mechanism from "../../assets/images/mechanism_ratio.webp"
import mechanism_gif from "../../assets/images/mechanism_ratio.gif"

import lm_lid from "../../assets/images/lm_lid.png"
import demo_delidding from "../../assets/images/demo_delidding.png"
import demo_assembly from "../../assets/images/demo_assembly.png"
import demo_thermal from "../../assets/images/demo_thermal.png"

import demo_delidding_webp from "../../assets/images/demo_delidding.webp"
import demo_assembly_webp from "../../assets/images/demo_assembly.webp"
import demo_thermal_webp from "../../assets/images/demo_thermal.webp"

import {isWebpSupported} from 'react-image-webp/dist/utils';

export default class LMLid extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: this.props.pageNum,
      modal: 0,
      loaded: false
    };
  }

  componentDidMount() {
    this.state.loaded = true;
    this.setState(this.state);
  }

  togglePage(pageNum) {
    this.setState({
      page: pageNum
    });
  }

  toggleModal(modalState) {
    this.setState({
      modal: modalState
    });
  }

  renderModal() {
    switch(this.state.modal) {
      case 0: return;
      case 1: return (
        <div className="modal-shell" onClick={() => this.toggleModal(0)}>
          <div className="image-modal" onClick={(e) => e.stopPropagation()}>
            <img src={mechanism}/>
          </div>
        </div>
      )
    }
  }

  handleLoad() {
    this.setState({loaded: true})
  }

  renderLoading(type) {
    let {loaded} = this.state;
    return !loaded ? <div className="loading-product"><div>Loading...</div><div>(can also right click on gray area to download)</div></div> : <div></div>;
  }

  renderPage() {
    switch(this.state.page) {
      case 0: return (
        <div className="split-section">
          <div className="left-half">
            <img src={lm_lid} />
          </div>
          <div className="right-half">
            <div className="page-section">
              <div className="page-section-content">
                LM-Lid is an integrated heat spreader (IHS) with a
                reservoir structure, which consists of a tunnel type of
                reservoir, a seal ring and a connecting hole. This designs
                allows for the safe use of liquid metal TIM in lidded flip chip
                packages, in which a very thin LM-TIM layer is formed between
                the lid and flip chip. This guarantees ideal thermal
                performance, minimal mechanical stress, and zero risk of
                leakage.
              </div>
              <div className="page-section-content font-gray">
                STATUS: UNDERGOING INDUSTRIAL VERIFICATION
              </div>
            </div>
          </div>
        </div>
      );
      case 1: return (
        <div className="split-section">
          {/* <div className="left-half">
            <div className="product-carousel-wrapper">
              <Carousel
                itemsToShow={1}
                className="product-carousel no-dots"
                key="1"
              >
                <div className="product-slide">
                  <div className="slide-title">Working Mechanism</div>
                  <div className="slide-content">
                    <img src={mechanism} className="camera-photo" />
                  </div>
                </div>
              </Carousel>
            </div>
          </div> */}
          <div className="left-half">
            <img
              src={isWebpSupported() ? mechanism : mechanism_gif}
              className="modal-image"
              onClick={() => this.toggleModal(1)}
              onLoad={() => this.handleLoad()}
            />
            {this.renderLoading()}
          </div>
          <div className="right-half">
            <div
              className="page-section"
              style={{ "justify-content": "center" }}
            >
              <div className="page-section-title">Working Principle</div>
              <div
                className="page-section-content"
                style={{ "margin-top": "0.5vw" }}
              >
                An external pressure is used to simulate changes in gap volume
                due to warpage change. When the volume of the sealed gap between
                chip and lid gets smaller or larger due to warpage change, the
                reservoir can take in/release the necessary amount amount of
                liquid metal from/to the sealed gap. This makes sure that the
                sealed gap is fully filled with liquid metal and has safe levels
                of liquid pressure.
              </div>
            </div>
          </div>
        </div>
      );
      case 2: return (
        <div className="split-section-col">
          <div className="demo-carousel-wrapper">
            <Carousel itemsToShow={1} className="product-carousel" key="2">
              <div className="demo-slide">
                <div className="demo-slide-title underline-title">
                  Fig. 1, Delidding of Intel i5 8600k to uncover the paste TIM1
                </div>
                <div className="demo-slide-content">
                  <img src={!isWebpSupported() ? demo_delidding_webp : demo_delidding} className="width-image" />
                  <div className="demo-slide-description">
                    Fig. 1 shows an Intel i5 8600k with paste TIM1, which is the
                    test vehicle for our demo. The paste TIM1 theoretically causes
                    temperature rise by about 10C for a power density of 50W/cm2.
                  </div>
                </div>
              </div>
              <div className="demo-slide">
                <div className="demo-slide-title">
                  Fig. 2, Demo of the LM-Lid and the LM-Lidded i5 8600k w/
                  LM-TIM1
                </div>
                <div className="demo-slide-content">
                  <img src={!isWebpSupported() ? demo_assembly_webp : demo_assembly} className="width-image" />
                  <div className="demo-slide-description">
                    Fig. 2 shows an LM-Lid made from acrylic material and its
                    lidded i5 8600k package to illustrate its working mechanism.
                    When the volume of the sealed gap between chip and lid gets
                    smaller or larger due to warpage change, the reservoir can
                    take in/release the necessary amount amount of liquid metal
                    from/to the sealed gap. This makes sure that the sealed gap is
                    fully filled with liquid metal and has safe levels of liquid
                    pressure.
                  </div>
                </div>
              </div>
              <div className="demo-slide">
                <div className="demo-slide-title">
                  Fig. 3, Original and LM-Lidded Intel i5 8600k packages, and
                  their thermal data
                </div>
                <div className="demo-slide-content">
                  <img src={!isWebpSupported() ? demo_thermal_webp : demo_thermal} className="width-image" />
                  <div className="demo-slide-description">
                    Fig. 3 shows the LM-Lidded i5 8600k w/ a copper LM-Lid and its
                    thermal test data, where the copper LM-Lid has the same
                    reservoir structure as the acrylic demo. The LM-Lidded i5
                    8600k reduces the chip temperature by about 10C when compared
                    to the original package with the paste TIM1. Furthermore, the
                    LM-Lidded i5 8600k has also passed simple shock/vibration
                    reliability tests, which preliminarily verify the feasibility
                    of LM-Lid.
                </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      );
      case 3: return (
        <div className="split-section">
          <div className="demo-slide">
            <div className="demo-slide-content">
              <img
                src={demo_thermal}
                className="width-image"
                style={{ padding: "none" }}
              />
              <div
                className="demo-slide-description"
                // style={{ "font-size": "1.3vw" }}
              >
                The images above show the LM-Lidded i5 8600k w/ a copper LM-Lid
                and its thermal test data, where the copper LM-Lid has the same
                reservoir structure as the acrylic demo. The LM-Lidded i5 8600k
                prototype has an operating temperature about 10C lower than the
                original package with the paste TIM1. It has also passed simple
                shock/vibration reliability tests, which preliminarily verify
                the feasibility of the LM-Lid.
              </div>
            </div>
          </div>
          {/* <div className="left-half">
            <div className="product-carousel-wrapper">
              <Carousel
                itemsToShow={1}
                className="product-carousel"
                key="3"
              >
                <div className="product-slide">
                  <div className="slide-title">
                    Thermal Performance - Intel 8600K CPU
                  </div>
                  <div className="slide-content">
                    <img src={lm_lid_thermal} className="camera-photo"/>
                  </div>
                </div>
                <div className="product-slide">
                  <div className="slide-title">
                    Original and LM-Lidded Intel i5 8600K Test Vehicles
                  </div>
                  <div className="slide-content">
                    <img src={test_vehicles} className="camera-photo" />
                  </div>
                </div>
              </Carousel>
            </div>
          </div>
          <div className="right-half">
            <div className="page-section">
              <div className="page-section-content">
                The LM-Lidded i5 8600k prototype has an operating temperature
                about 10C lower than the origignal package with the paste TIM1.
                <div className="page-section-content"></div>
                The LM-Lidded i5 8600K has also passed simple shock/vibration
                reliability tests, which preliminarily verify the feasibility of
                the LM-Lid.
              </div>
            </div>
          </div> */}
        </div> 
      );
    };
  }

  render() {
    return (
      <div className="page" id="lm-lid-page">
        <div className="center white-back">
          <div className="page-banner">
            <div className="page-banner-text">Liquid Metal Lid (LM-Lid)</div>
            <div className="page-buttons">
              <div className="page-button blue-link" onClick={() => this.togglePage(0)}>Product Summary</div>
              <div className="page-button-spacer">|</div>
              <div className="page-button blue-link" onClick={() => this.togglePage(2)}>Demo</div>
              <div className="page-button-spacer">|</div>
              <div className="page-button blue-link" onClick={() => this.togglePage(1)}>Working Principle</div>
              <div className="page-button-spacer">|</div>
              <div className="page-button blue-link" onClick={() => this.togglePage(3)}>Thermal Performance</div>
            </div>
          </div>
          {this.renderPage()}
          {this.renderModal() }
        </div>
      </div>
    );
  }
}