import React from "react";
import warpage_1 from "../../assets/images/warpage_1.png"
import warpage_2 from "../../assets/images/warpage_2.png"
import warpage_22 from "../../assets/images/warpage_2.2.png"

// Sections, each section has 1 photo and a block of content

export default class Warpage extends React.Component {
  render() {
    return (
      <div className="page" id="warpage-page">
        <div className="center white-back">
          <div className="page-banner">Fundamentals: Warpage</div>
          <div className="split-section">
            <div className="left-half">
              <div className="page-section">
                <div className="page-section-title">Thermal Expansion</div>
                <div className="page-section-content">
                  <div>
                    Due to the CTE (coefficient of thermal expansion) mismatch
                    between the die and substrate, they will expand and shrink
                    at different rates when the temperature changes. As a result,
                    the package becomes concave at low temperatures and convex
                    at high temperatures - a phenomenon referred to as the
                    warpage behavior of flip chip packages. 
                    To control warpage, a stiff metal lid is attached to the flip
                    chip packages that pushes and pulls at the die and substrate 
                    to keep the package flat. 
                  </div>
                </div>
              </div>

              <div className="page-section">
                <div className="page-section-title">
                  Reliability Issues: Stress Induced Cracking
                </div>
                <div className="page-section-content">
                  When the lid and chip are bonded together by TIM1, their
                  different rates of deformation will cause an additional stress
                  at the corner and edge region of the chip and TIM1, resulting
                  in reliability issues including TIM1 delamination and chip
                  failures. For the paste TIM1, this additional stress is not
                  very severe due to its low Young’s modulus. However, for
                  soldered metal TIM1 ( STIM ), the additional stress is very
                  high, and Intel itself has cited “the potential for
                  catastrophic cracks in the metal.” when asked about STIM.
                </div>
              </div>

              <div className="page-section">
                <div className="page-section-title">
                  Reliability Issues: Leakage of Liquid Thermal Interface
                  Materials
                </div>
                <div className="page-section-content">
                  Warpage has also made it difficult to use liquid thermal
                  interface materials. Due to the warpage, the volume of the gap
                  between the lid and the chip will change greatly with
                  temperature. The gap volume during upwards/concave warpage can
                  be as much as double that of the gap volume during
                  downwards/convex warpage. So, if we only use a seal ring to
                  seal the gap, the gap will not be completely filled when the
                  gap volume becomes larger, and the liquid pressure in the gap
                  will become very high when the gap volume becomes smaller.
                  This is the fundamental reason why prior arts with seal ring
                  structures failed to resolve the leakage concern for using
                  LM-TIM in lidded flip chip packages.
                </div>
              </div>
            </div>
            <div className="right-half center-content flex-col">
              <img src={warpage_2} className="page-image"/>
              <img src={warpage_1} className="page-image" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
