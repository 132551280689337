import React from "react";

import NavBar from "./nav/nav_bar"
import Home from "./pages/home"

import About from "./pages/about"
import Team from "./pages/team"
import Patents from "./pages/patents"

import LM from "./pages/lm"
import Warpage from "./pages/warpage"
import TIM from "./pages/tim"


import LMLid from "./pages/lm_lid"
import LMHS from "./pages/lm_hs"
import LMVC from "./pages/lm_vc"
import LMVCLid from "./pages/lm_vc_lid"

import background from "../assets/images/background.png"
import background_webp from "../assets/images/background.webp"

import assembly_0 from "../assets/images/assembly_1.png"
import assembly_1 from "../assets/images/assembly_2.png"
import assembly_2 from "../assets/images/assembly_3.png"
import assembly_3 from "../assets/images/assembly_4.png"
import assembly_4 from "../assets/images/assembly_5.png"
import assembly_5 from "../assets/images/assembly_7.png"

import assembly_0_webp from "../assets/images/assembly_1.webp"
import assembly_1_webp from "../assets/images/assembly_2.webp"
import assembly_2_webp from "../assets/images/assembly_3.webp"
import assembly_3_webp from "../assets/images/assembly_4.webp"
import assembly_4_webp from "../assets/images/assembly_5.webp"
import assembly_5_webp from "../assets/images/assembly_7.webp"

import Carousel from "react-elastic-carousel"
import {isWebpSupported} from 'react-image-webp/dist/utils';


class Root extends React.Component {
  constructor() {
    super();

    this.state = {
      page: 0,
      pageOption: 0,
      history: [],
      modal: 0,
      loaded: false
    };

    this.changePage = this.changePage.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {
    this.state.loaded = true;
    this.setState(this.state);
  }

  changePage(newPage, pageOption=0) {
    this.state.history.push(this.state.page);
    this.setState({
      page: newPage,
      pageOption: pageOption,
      history: this.state.history,
      modal: this.state.modal
    });
  }

  toggleModal(modalState) {
    this.setState({
      page: this.state.page,
      history: this.state.history,
      modal: modalState
    });

    console.log(modalState)
  }

  renderModal() {
    switch(this.state.modal){
      case 0: return (<div></div>);
      case 1: return (
        <div className="modal-shell" onClick={() => this.toggleModal(0)}>
          <div id="contact-modal" onClick={e => e.stopPropagation()}>
            <div className="title">Contact Us</div>
            <div className="content">(312) 509-3315</div>
            <div className="content">kwang@iccooling.com</div>
          </div>
        </div>
      );
      //TODO: ADD ANOTHER UNCLICKABLE MARGIN TO PREVENT ACCIDENTAL CLOSES
      //TODO: ADD X TO CLOSE MODAL
      //TODO: ADD STEP 6 - A NICE PICTURE FOR COMPLETED ASSEMBLY
      case 2: return ( 
        <div className="modal-shell" onClick={() => this.toggleModal(0)}>
          <div className="carousel-modal" onClick={(e) => e.stopPropagation()}>
            <Carousel
              itemsToShow={1}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="home-slide-modal">
                <div className="slide-title">Step 0: Pre-assembly</div>
                <div className="slide-content">
                  <img src={isWebpSupported() ? assembly_0_webp : assembly_0} id="assembly-gif-big" />
                </div>
              </div>
              <div className="home-slide-modal">
                <div className="slide-title">Step 1: Attach Flip Chip Package on PCB</div>
                <div className="slide-content">
                  <img src={isWebpSupported() ? assembly_1_webp : assembly_1} id="assembly-gif-big" />
                </div>
              </div>

              <div className="home-slide-modal">
                <div className="slide-title">Step 2: Attach Heat Sink Base Plate</div>
                <div className="slide-content">
                  <img src={isWebpSupported() ? assembly_2_webp : assembly_2} id="assembly-gif-big" />
                </div>
              </div>
              <div className="home-slide-modal">
                <div className="slide-title">Step 3: Begin TIM2 Injection</div>
                <div className="slide-content">
                  <img src={isWebpSupported() ? assembly_3_webp : assembly_3} id="assembly-gif-big" />
                </div>
              </div>
              <div className="home-slide-modal">
                <div className="slide-title">Step 4: Complete TIM2 Layer</div>
                <div className="slide-content">
                  <img src={isWebpSupported() ? assembly_4_webp : assembly_4} id="assembly-gif-big" />
                </div>
              </div>
              <div className="home-slide-modal">
                <div className="slide-title">Step 5: Attach Heat Sink Fins</div>
                <div className="slide-content">
                  <img src={isWebpSupported() ? assembly_5_webp : assembly_5} id="assembly-gif-big" />
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      );
    }
  }

  renderPage() {
    switch(this.state.page) {
      case 0:
        return (<Home changePage={this.changePage} toggleModal={this.toggleModal}/>)
      case 1:
        return (<About />) // ABOUT
      case 2:
        return (<Team />)
      case 3:
        return (<Patents />)
      case 4:
        return (<LM />) // Liquid Metal
      case 5:
        return (<Warpage />) // Warpage
      case 6:
        return (<TIM />) // TIMs
      case 7:
        return (<LMLid pageNum={this.state.pageOption}/>) // LM-Lid
      case 8:
        return (<LMHS />) // LM-HS
      case 9:
        return (<LMVC />) // LM-VC
      case 10:
        return (<LMVCLid />) // LM-VC-Lid
    }
  }

  render() {
    return (
      <div id="root">
        <div className="background-image-container">
          <div className="background-spacer"></div>
          <img src={isWebpSupported() ? background_webp : background} className="background-image" />
        </div>
        <NavBar changePage={this.changePage} toggleModal={this.toggleModal}/>
        <div className="nav-spacer"></div>
        { this.renderPage() }
        { this.renderModal() }
      </div>
    );
  }
}

export default Root;
