import React from "react"
import ReactPlayer from "react-player"

import tim_table from "../../assets/images/tim_table.png"

// Put content on left side
// Put two ideos - one about liquid gallium, one about lm-tim testing

export default class LM extends React.Component {

  render() {
    return (
      <div className="page" id="lm-page">
        <div className="center white-back">
          <div className="page-banner">Fundamentals: Liquid Metal</div>
          <div className="split-section">
            <div className="left-half">
              <div className="page-section">
                <div className="page-section-title">What is Liquid Metal?</div>
                <div className="page-section-content">
                  <div>
                    The most popular liquid metal for TIM is gallium and its
                    various alloys. Gallium is a non-toxic metal material that
                    has a melting point of about 29.8C, while its alloys can be
                    designed to have a melting point below or above room
                    temperature. It is well known that LM-TIM (liquid metal TIM)
                    is the best TIM material due to its low thermal impedance
                    and its fluid state at operating temperatures that does not
                    introduce additional stress into the package. Despite these
                    excellent features, however, LM-TIM has not been adopted by
                    the IC chip industry due there being no good solution for
                    resolving its leakage concern.
                  </div>
                </div>
              </div>
              <div className="page-section">
                <div className="page-section-title">
                  Gallium Technical Specs
                </div>
                <div className="page-section-content">
                  <div className="table-row">
                    <div className="table-left">Chemical Symbol</div>
                    <div className="table-center">&mdash;</div>
                    <div className="table-right">Ga</div>
                  </div>
                  <div className="table-row">
                    <div className="table-left">Atomic Number</div>
                    <div className="table-center">&mdash;</div>
                    <div className="table-right">31</div>
                  </div>
                  <div className="table-row">
                    <div className="table-left">Thermal Conductivity</div>
                    <div className="table-center">&mdash;</div>
                    <div className="table-right">40.6 W/(m·K)</div>
                  </div>
                  <div className="table-row">
                    <div className="table-left">Melting Point</div>
                    <div className="table-center">&mdash;</div>
                    <div className="table-right">29.76 &#730;C</div>
                  </div>
                  <div className="table-row">
                    <div className="table-left">Young's Modulus</div>
                    <div className="table-center">&mdash;</div>
                    <div className="table-right">9.8 GPa</div>
                  </div>
                  <div className="table-row">
                    <div className="table-left">Density</div>
                    <div className="table-center">&mdash;</div>
                    <div className="table-right">
                      5.904 g/cm<sup>3</sup>
                    </div>
                  </div>
                </div>
              </div>
              <div className="page-section">
                <div className="page-section-title">Comparison Chart</div>
                <div className="page-section-content"></div>
                <div className="page-section-title">
                  <img src={tim_table} className="page-image"/>
                </div>
              </div>
            </div>
            <div className="right-half split-section-col">
              <div className="video-player">
                <ReactPlayer
                  url="https://www.youtube.com/watch?v=IADwGAFAQJ0"
                  width="60vh"
                  height="30vh"
                />
              </div>
              <div className="video-player">
                <ReactPlayer
                  url="https://www.youtube.com/watch?v=TCu4IsUEyCA"
                  width="60vh"
                  height="30vh"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}