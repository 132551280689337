import React from "react"
import warpage_1 from "../../assets/images/warpage_1.png";

// Introduce types of TIM: grease, phase change, film, solder-TIM
// For each, talk about where its commonly used

export default class TIM extends React.Component {
  render() {
    return (
      <div className="page" id="tim-page">
        <div className="center white-back">
          <div className="page-banner">
            Fundamentals: Thermal Interface Materials
          </div>
          <div className="split-section">
            <div className="left-half">
              <div className="page-section">
                <div className="page-section-title">Thermal Paste</div>
                <div className="page-section-content">
                  <div>
                    Thermal paste is traditionally used in lidded flip chip
                    packages and consists of a polymerized matrix material
                    filled with thermally conductive particles. One limit of
                    paste TIM1 is that it must be cured. If the paste TIM1 is
                    not cured, then it will pump out due to the warpage behavior
                    of the chips when the package heats up and cools down,
                    forming voids or empty regions in the gap between the lid
                    and chip that result in thermal failure. Furthermore,
                    thermal paste can only be used in packages with low power
                    consumption due to its high thermal impedance; even in these
                    packages, however, it is often blamed for poor thermal
                    performance and reliability issues.
                  </div>
                </div>
              </div>
            </div>
            <div className="right-half center-content"></div>
          </div>
          <div className="split-section">
            <div className="left-half">
              <div className="page-section">
                <div className="page-section-title">Hitachi Graphite Film</div>
                <div className="page-section-content">
                  Hitachi has developed a graphite film for TIM1 that can be
                  cured and bonded with the lid and chip. Hitachi has stated
                  that the thermal impedance of their graphite film can be as
                  small as 10-15 C·mm2/W and is proposing the graphite film for
                  use in the IC chip industry. In comparison, the thermal
                  impedance of paste TIM1 is about 20 C·mm2/W, which means that
                  for a chip with power density of 100W/cm2, the temperature
                  rise due to the graphite film and the cured paste are 10-15C
                  and 20C, respectively. For further comparison, the thermal
                  impedance of liquid metal TIM1 is almost zero, meaning that
                  the temperature rise due to liquid metal TIM1 is almost zero.
                  Thus, while its thermal performance is much better than that
                  of thermal paste, it is still far worse than that of STIM or
                  LM-TIM. It also relies on a polymer layer to bond to the lid
                  and chip, so it is susceptible to thermal issues caused by
                  delamination due to polymer material degradation during long
                  term application.
                </div>
              </div>
            </div>
            <div className="right-half center-content"></div>
          </div>
          <div className="split-section">
            <div className="left-half">
              <div className="page-section">
                <div className="page-section-title">Solder Metal (STIM)</div>
                <div className="page-section-content">
                  STIM is used in the most recent generation of AMD and Intel
                  CPUs. To create the STIM layer, the chip and lid surfaces are
                  first plated in gold, and then soldered together with a piece
                  of indium alloy. While this setup has excellent thermal
                  performance, it introduces a very high additional stress into
                  the package and drastically reduces the reliability of its CPU
                  products. When it was asked about the pros and cons of STIM,
                  Intel has stated that packages using STIM can potentially form
                  catastrophic cracks in the metal. Because of these reliability
                  issues, STIM cannot be used in high-end CPU and GPU products
                  with integrated HBM (high band memory), as HBM chips are are
                  multilayered 3D chip packages with layers of silicon as thin
                  as 50-100 microns, that can never withstand the high
                  additional stress caused by the STIM. However, these types of
                  high-end GPU/HBM products also cannot use paste TIM1 as they
                  tend to have extremely high power consumption, so there is an
                  urgent need for a TIM1 solution that is both reliable and
                  thermally effective.
                </div>
              </div>
            </div>
            <div className="right-half center-content"></div>
          </div>
          <div className="split-section">
            <div className="left-half">
              <div className="page-section">
                <div className="page-section-title">Liquid Metal (LM-TIM)</div>
                <div className="page-section-content">
                  It is well-known that LM-TIM is the best TIM material due to
                  its 1) high thermal conductivity, 2) good surface wetting
                  capability for almost zero contact thermal resistance, and 3)
                  fluid state that introduces no additional stress. However,
                  LM-TIM has a critical problem that has prevented its industry
                  application in lidded flip chip packages: the leakage issue
                  when lidded flip chip packages with LM-TIM undergo various
                  standard reliability tests, such as the shock and vibration
                  test, thermal cycling test, and so on. This issue has limited
                  LM-TIMs effectiveness to personal aftermarket use, where
                  lidded packages using LM-TIM can stay in a stable environment
                  and do not have to worry about leakage. Our patented LM-Lid
                  resolves this leakage issue and enables the industrial
                  application of LM-TIMs. The LM-Lid combines two traditional
                  products, the lid and the TIM, into one product, such that a
                  liquid metal TIM1 layer is automatically formed when applying
                  the LM-Lid to a flip chip package.
                </div>
              </div>
            </div>
            <div className="right-half center-content"></div>
          </div>
        </div>
      </div>
    );
  }
}