import React from "react";
import yuci from "../../assets/images/Temp.jpg"
import kevin from "../../assets/images/Temp copy.jpg"

class Team extends React.Component {
  constructor() {
    super();
    this.members = [
      {
      "image": yuci,
      "email": "shen.yuci1@gmail.com",
      "phone": "408-316-7173",
      "name": "YUCI SHEN",
      "role": "Founder - R&D",
      "bio": "Yuci is the founder of ICCT and the principle investigator for the LM-Lid project. He completed his B.S. in Electrical Engineering at UCLA in 2020, and has worked on projects related to eliminating thermal and reliability issues in IC chip packages since he was a high school student. In 2015, Yuci became the youngest speaker in ECTC (Electronic Components and Technology Conference) history for his study on warpage control of lidded flip chip packages. He published two papers investigating thermal and warpage problems in lidded flip chip packages, and received the IEEE Transactions on Components, Packaging and Manufacturing Technology best paper award in 2016. Asides from the four patents associated with this project, Yuci holds several other patents regarding the manufacturing and design of flip chip package components."
      },
      {
        "image": kevin,
        "email": "ningwangiit@gmail.com",
        "phone": "",
        "name": "KEVIN WANG",
        "role": "Opeations - Commercialization",
        "bio": "Kevin is in charge of commercialization and operations. He completed his B.S. in Global Economics at Shanghai University of Finance & and Economics in 2014, with a minor in Computer Science and Technology. His curriculum included Mathematical Statistics, Accounting, Mathematical Analysis, Microeconomics, and Macroeconomics. Kevin later completed his MPA (Master of Public Administration) in 2015 at the Illinois Institute of Technology. He has worked at multiple start-ups, and is extremely experienced in entrepreneurship and start-up management."
      }
    ];
  }

  render() {
    const membersList = this.members.map( pair => {
      return (
        <div className="team-member">
          <div className="team-member-left">
            <img src={pair["image"]}/>
            <div className="team-member-phone">{pair["phone"]}</div>
            <div className="team-member-email">{pair["email"]}</div>
          </div>
          <div className="team-member-right">
            <div className="team-member-line"></div>
            <div className="team-member-name">{pair["name"]}</div>
            <div className="team-member-role">{pair["role"]}</div>
            <div className="team-member-bio">{pair["bio"]}</div>
          </div>
        </div>
      );
    });

    return (
      <div className="page" id="team-page">
        <div className="center white-back">
          <div className="page-banner">Our Team</div>
          {membersList}
        </div>
      </div>
    );
  }
}

export default Team