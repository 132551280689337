import React from "react"
import NavButtons from "./nav_buttons"
import NavLogo from "./nav_logo"
import "./nav.css"

export default class NavBar extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="nav-bar">
        <NavLogo
          changePage={this.props.changePage}
          toggleModal={this.props.toggleModal}
        />
        <NavButtons changePage={this.props.changePage} />
      </div>
    );
  }
}